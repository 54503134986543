<template lang="pug">
section.reviews
  .container
    h2.title.is-italic {{ $t('homePage.reviews.title') }}
    .sub-title(v-html="$t('homePage.reviews.subTitle', socialLinks)")
    .embedsocial-hashtag
</template>

<script>
import { linksEmbedSocial } from '~~/utils/definitions/defaults'
export default {
  name: 'ReviewsComponent',

  computed: {
    socialLinks() {
      const links = linksEmbedSocial[process.env.siteName]
      if (!links) {
        return {}
      }

      return {
        google: links.google,
        verifiedReviews: linksEmbedSocial.verifiedReviews
      }
    }
  },

  beforeDestroy() {
    this.$embedSocial.kill()
  },

  mounted() {
    this.$embedSocial.insert()
  }
}
</script>

<style lang="sass" scoped>
.reviews
  width: 100%
  margin-bottom: 100px
  +touch
    margin-bottom: 50px
  .sub-title
    text-align: center
    font-size: 18px
    margin-bottom: 30px
    ::v-deep
      a
        color: $default-font-color
        transition: all 0.3s ease
        &:hover
          color: $primary-color
  .title
    +mobile
      font-size: 36px
  .embedsocial-hashtag
    min-height: 377px
</style>
